<template>
  <div class="credit">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">征信产品中心</div>
    <div class="title flex">
      <img src="../../assets/img/zxlogo1.png" alt="">
      <p>百行征信有限公司</p>
    </div>
    <div class="xxcontent">
    <div class="xcontent flex-wrap">
      <div class="newzxcp" @click="navTo('https://www.baihangcredit.com/main/enterProds/enterRelated')">
          <div><img style="width: 100%;" src="../../assets/img/zx3.png" alt=""></div>
          <div class="divzx1">共享数据类产品</div>
          <div class="divzx2">小微企业信用报告结合小微、普惠金融业务特征...</div>
          <div class="divzx3">查看详情>></div>
      </div>
      <div class="newzxcp" @click="navTo('https://www.baihangcredit.com/main/enterProds/enterRelated')">
          <div><img style="width: 100%;" src="../../assets/img/zx4.png" alt=""></div>
          <div class="divzx1">基础征信服务产品</div>
          <div class="divzx2">基于百行个人征信数据库的基础征信产品...</div>
          <div class="divzx3">查看详情>></div>
      </div>
    </div>
    </div>
    <div class="title flex">
      <img src="../../assets/img/zxlogo.png" alt="">
      <p>广西联合征信有限公司</p>
    </div>
    <div class="xxcontent">
    <div class="xcontent flex-wrap">
      <div class="newzxcp" @click="navTo('https://lhzx.linkfinance.cn/')">
          <div><img style="width: 100%;" src="../../assets/img/zx1.png" alt=""></div>
          <div class="divzx1">企业信用报告</div>
          <div class="divzx2">深度挖掘企业授权下的场景数据、产业链数据等数据...</div>
          <div class="divzx3">查看详情>></div>
      </div>
      <div class="newzxcp" @click="navTo('https://lhzx.linkfinance.cn/')">
          <div><img style="width: 100%;" src="../../assets/img/zx2.png" alt=""></div>
          <div class="divzx1">风险监测预警</div>
          <div class="divzx2">利用大数据互联与关联关系挖掘技术...</div>
          <div class="divzx3">查看详情>></div>
      </div>
    </div>
    </div>
   
  </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
   setup() {
     let router = useRouter();
     const navTo = (url) => {
        router.push({
          name:'frame',
          params:{
          url: url
          }
        })
        // window.location.href = url;
    };
    return { 
      navTo
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.credit {
  padding-top: 12.5vw;
  .title {
    img {
      height: 6vw;
      width: auto;
      margin-right: 2vw;
    }
      font-size: 5vw;
      font-weight: 600;
      margin: 4vw;
  }
  .xxcontent {
    margin: 4vw;
    width: 92vw;
    // overflow-x: auto;
  }
  .xcontent {
      width: 100%;
    .newzxcp{
      width: 44vw;
      background-color: #fff;
      height: 100%;
      border-radius: 1.5vw;
      margin: 0 2vw 2vw 0;
      // overflow: hidden;
      .divzx1{        
        padding: 0 3vw;
        font-size: 4vw;
        color: #333;
        margin-top: 1vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .divzx2{
        padding: 0 3vw;
        margin-top: 1vw;
        font-size: 3.5vw;
        color: #999;
        display: -webkit-box;
        display: -moz-box;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp:2;  
      }
      .divzx3 {
          padding: 0 4vw;
          margin-top: 1vw;
          text-align: right;
          margin-bottom: 4vw;
          color: #ff9900;
          font-size: 4vw;
      }
    }
  }

}
</style>
